import fetch from "isomorphic-fetch";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";

const CoreEndpoint = new HttpLink({
  uri: process.env.NODE_ENV === "development" ? process.env.GATSBY_API_CORE : "https://api.swapcard.com/graphql",
  fetch,
});

const StudioEndpoint = new HttpLink({
  uri: process.env.NODE_ENV === "development" ? process.env.GATSBY_API_STUDIO : "https://studio-api.swapcard.com/graphql",
  fetch,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.split((operation) => operation.getContext().clientName === "studio", StudioEndpoint, CoreEndpoint),
});

function headerMiddleware(headers: any) {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers: existingHeaders = {} }) => ({
      headers: {
        ...existingHeaders,
        ...headers,
      },
    }));

    return forward(operation);
  });
}

export function setClientHeaders(headers: Record<string, any>) {
  const middleware = headerMiddleware(headers);

  const newCoreEndpoint = middleware.concat(CoreEndpoint);
  const newStudioEndpoint = middleware.concat(StudioEndpoint);

  client.setLink(
    ApolloLink.split((operation) => operation.getContext().clientName === "studio", newStudioEndpoint, newCoreEndpoint)
  );
}

export default client;
