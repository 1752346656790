import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { TFont } from "../application-provider/fonts-helpers";

export const GlobalStyle = createGlobalStyle<{ font?: TFont }>`
  ${reset}

  html {
    font-size: 15px;
    overflow-x: hidden;
    font-family: "${({ font }) => font?.family || "Work Sans"}", Arial, Helvetica, sans-serif;
  }


  body {
    margin: 0;
    padding: 0;
    font-family: "${({ font }) => font?.family || "Work Sans"}", Arial, Helvetica, sans-serif;
    color: rgb(38, 47, 61);
    line-height: 1.1;
    height:100% ;
  }

  *,
  :after,
  :before {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    font-family: "${({ font }) => font?.family || "Work Sans"}", Arial, Helvetica, sans-serif;
    /* box-sizing: border-box; */ 
    /* Note: Do not enable the style in the line above, as it affects all pages. Instead, add it in a separate global style for each inidividual page. Thank you! */
  }

  .img,
  .img-responsive {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
  }

  *:focus {
    outline: 0;
  }

  .img,
  .img-responsive {
    width: auto;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .tooltip-wrapper {
    max-width:300px;
  }

`;

export const ThemeDefault = {
  colors: {
    secondary: "#026d68",
    primary: "#026d68",
    background: "#fafafa",
    separator: "#e5e5e5",
    danger: "#FF0000",
    gray: "",
    input: "#e6e6e6",
    text: "rgb(104, 104, 104)",
    textPrimary: "#374255",
    textLight: "#374255",
    white: "#FFF",
    actionsPrimary: "#026d68",
    borders: "#e6e6e6",
  },
  box: {
    primary: "0px 0px 1px 0px #0000003d",
    secondary: "rgba(0, 0, 0, 0.14) 0 1px 3px 0;",
  },
  fontSize: {
    xs: "0.5rem",
    sm: "0.9rem",
    base: "1rem",
    lg: "1.1rem",
    xl: "1.2rem",
    "2xl": "1.5rem",
    "3xl": "1.8rem",
    "4xl": "2rem",
  },
};
