import React from "react";
import ApolloClient from "./src/gatsby-plugin-apollo/client";
import { ApolloProvider } from "@apollo/client";
import ThemeProvider from "@swapcard-corporation/react-ui/lib/theme-provider";
import { GlobalStyle } from "./src/components/layout/theme";

export function wrapRootElement({ element }) {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <ApolloProvider client={ApolloClient}>{element}</ApolloProvider>
    </ThemeProvider>
  );
}
